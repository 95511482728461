import { useAccount, useDisconnect } from "wagmi";

export function useAuth() {
  const { address } = useAccount();

  const { disconnect } = useDisconnect({
    onSuccess() {
      window.localStorage.clear();
    },
  });

  return {
    isAuthorized: !!address,
    walletId: address || null,
    disconnect,
  };
}

export default useAuth;
