import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { routes, stages } from "constants";
import {
  ComingSoon,
  Landing,
  Mint,
  PublicMint,
  SecretPrize,
  ClaimYourCrocs,
} from "pages";
import { getStage, secretPrizeExpired } from "utils";
import { createClient, WagmiConfig } from "wagmi";
import { connectors, provider, webSocketProvider } from "services/wallet";
const stage = getStage();

function App() {
  const client = createClient({
    autoConnect: true,
    provider,
    webSocketProvider,
    connectors,
  });

  const hasSecretPriceExpired = secretPrizeExpired();

  useEffect(() => {
    const onResize = () => {
      document.documentElement.style.setProperty(
        "--app-height",
        `${window.innerHeight}px`
      );
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const renderRoutes = () => {
    switch (stage) {
      case stages.stage1:
        return <Route path={routes.HOME} element={<ComingSoon />} />;
      case stages.stage2:
        return <Route path={routes.HOME} element={<Landing />} />;
      case stages.stage3:
        return (
          <>
            <Route path={routes.HOME} element={<Landing />} />;
            <Route path={routes.MINT} element={<Mint />} />;
          </>
        );
      case stages.stage4:
        return (
          <>
            <Route path={routes.HOME} element={<Landing />} />;
            <Route path={routes.MINT} element={<PublicMint />} />;
          </>
        );
      case stages.stage5:
        return (
          <>
            <Route path={routes.HOME} element={<Landing />} />;
            <Route path={routes.MINT} element={<PublicMint />} />;
            <Route path={routes.SECRET} element={<SecretPrize />} />;
            {!hasSecretPriceExpired && (
              <Route
                path={routes.CLAIM_YOUR_CROCS}
                element={<ClaimYourCrocs />}
              />
            )}
            ;
          </>
        );
      default:
        return <Route path={routes.HOME} element={<ComingSoon />} />;
    }
  };

  return (
    <WagmiConfig client={client}>
      <Routes>{renderRoutes()}</Routes>
    </WagmiConfig>
  );
}

export default App;
