import PropTypes from "prop-types";
import Icons from "./iconList";
import styles from "./Icon.module.scss";

export const Icon = ({ i, className = "", ...rest }) => {
  const I = Icons[i];
  return !I ? (
    <span>{i}</span>
  ) : (
    <svg
      className={`${styles.icon} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={`${i} icon`}
      role="graphics-document"
      viewBox={I.viewBox}
      fill={I.fill || "currentColor"}
      preserveAspectRatio={I.preserveAspectRatio || "xMidYMid"}
      {...rest}
    >
      {I.path.map((p, i) => {
        if (typeof p === "string")
          return <path key={`${p.substring(0, 10)}-${i}`} d={p} />;
        return <path key={`${p.d.substring(0, 10)}-${i}`} {...p} />;
      })}
    </svg>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  i: PropTypes.oneOf(Object.keys(Icons)),
};

export default Icon;
