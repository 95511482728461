import { useContractRead } from "wagmi";
import area54PacksAbi from "abi/Area54Packs.json";

const PACKS_CONTRACT_ADDRESS = process.env.REACT_APP_PACKS_CONTRACT_ADDRESS;

export function usePackContractRead(functionName, args = [], watch = false) {
  const { data, isError, isLoading } = useContractRead({
    address: PACKS_CONTRACT_ADDRESS,
    abi: area54PacksAbi,
    functionName,
    args,
    watch,
  });

  return {
    [`${functionName}Data`]: data,
    [`${functionName}Error`]: isError,
    [`${functionName}loading`]: isLoading,
  };
}
