export const routes = {
  HOME: "*",
  MINT: "/mint",
  SECRET: "/secret-prize",
  CLAIM_YOUR_CROCS: "/claim-your-crocs",
};

export const stages = {
  stage1: "phase1",
  stage2: "phase2",
  stage3: "phase3",
  stage4: "phase4",
  stage5: "phase5",
};

export const PACK_PRICE = 0.06;

export const WALLET_API = process.env.REACT_APP_WALLET_API;

export const vipCards = [
  {
    name: "Orion",
    number: "1",
  },
  {
    name: "DJ Alien Angel",
    number: "2",
  },
  {
    name: "Saturn Sam The Space Cowboy",
    number: "3",
  },
  {
    name: "Quarterback Squeak",
    number: "4",
  },
  {
    name: "Lorraine Yang",
    number: "5",
  },
  {
    name: "Bunnny Rabbbit",
    number: "6",
  },
  {
    name: "King Croc",
    number: "7",
  },
  {
    name: "Bee Alien",
    number: "8",
  },
  {
    name: "Invading Alien Family",
    number: "9",
  },
  {
    name: "Wing Man Miller",
    number: "10",
  },
  {
    name: "Mickey Fin ",
    number: "11",
  },
  {
    name: "Ronnnie Rabbbit",
    number: "12",
  },
  {
    name: "Poultry Rex",
    number: "13",
  },
  {
    name: "Cathy Cowgirl",
    number: "14",
  },
  {
    name: "Grinnie Saturn",
    number: "15",
  },
  {
    name: "Space Face UFO",
    number: "16",
  },
  {
    name: "UFO Liberty",
    number: "17",
  },
  {
    name: "Light Cult Crypto Alien",
    number: "18",
  },
  {
    name: "Orion Pod Slime",
    number: "19",
  },
  {
    name: "Gimmie Unipig",
    number: "20",
  },
  {
    name: "Sloe Moe UFO",
    number: "21",
  },
  {
    name: "Goldie Aquanaut",
    number: "22",
  },
  {
    name: "Triclopsian",
    number: "23",
  },
  {
    name: "Snailien King",
    number: "24",
  },
];
